import React from 'react'
import EventList from '../../Components/Admin/Event/EventList'

const AllEvents = () => {
  return (
    <>
      <div className='center_center'>
        <EventList />
      </div>
    </>
  )
}

export default AllEvents