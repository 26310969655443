import React from 'react'
import AlIGalleryImages from '../../../Components/Admin/Gallery/AlIGalleryImages'

const AllImages = () => {
  return (
    <>
      <AlIGalleryImages />
    </>
  );
}

export default AllImages